import * as React from "react";
import TwitterIcon from "-!svg-react-loader!./social-twitter.svg";
import WattpadIcon from "-!svg-react-loader!./social-wattpad.svg";
import InstagramIcon from "-!svg-react-loader!./social-instagram.svg";
import PatreonIcon from "-!svg-react-loader!./social-patreon.svg";
import "./SocialLinks.scss";

// markup
export default function SocialLinks(props) {
  return (
    <ul
      className={
        "social-list " +
        (props.size === "large" ? "social-list--large " : " ") +
        (props.className ? props.className : "")
      }
    >
      <li className="social-list__item">
        <a
          href="https://www.wattpad.com/yvetterussell"
          target="_blank"
          rel="noreferrer"
          className="social-list__link"
        >
          <WattpadIcon className="social-list__icon" />
        </a>
      </li>
      <li className="social-list__item">
        <a
          href="https://www.instagram.com/authoryvetterussell"
          target="_blank"
          rel="noreferrer"
          className="social-list__link"
        >
          <InstagramIcon className="social-list__icon" />
        </a>
      </li>
      <li className="social-list__item">
        <a
          href="https://www.twitter.com/yvetterussell"
          target="_blank"
          rel="noreferrer"
          className="social-list__link"
        >
          <TwitterIcon className="social-list__icon" />
        </a>
      </li>
    </ul>
  );
}
